import axios from "axios";
import {responses} from "@/utils/responses";

export default {
    name: 'tickets-documents',
    state: {
        documents: [],
    },
    getters: {
        getDocuments: (state) => state.documents
    },
    mutations: {
        setDocuments: (state, data) => state.documents = data
    },
    actions: {
        getDocumentOfTypeQuote: (context, customer) => {
            return axios.get(ticketApi + `ticket/document/quotes/${customer}`)
                .then(({data}) => context.commit('setDocuments', data))
                .catch(err => errorHandler.tomcatError(err))
        },

        deleteDocument: (context, id) => {
            return axios.delete(ticketApi + `ticket/document/${id}`)
                .then(({data}) => {
                    context.commit('setDocuments', context.state.documents.filter(doc => doc.id !== id))
                    return data
                })
                .catch(err => errorHandler.tomcatError(err))
        },
        saveDocument: (context, data) => {
            console.log(data)
            return axios.post(ticketApi + `ticket/document/${data.ticket}/attach`, data)
                .then((res) => {
                    return res.data
                })
                .catch(err => errorHandler.tomcatError(err))
        }
    }
}
