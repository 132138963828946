<template>
  <div >
    <button type="button" :id="id+`-modalButton`" class="btn btn-primary" data-bs-toggle="modal" :data-bs-target="`#${id}-customModal`"
            style="display: none" @click="$emit(`open`)"></button>
    <div class="modal fade" :id="id+`-customModal`" tabindex="-1" :aria-labelledby="id+`-customModalLabel`" aria-hidden="true">
      <div :class="`modal-dialog modal-${size} modal-dialog-scrollable`">
        <div class="modal-content" v-if="!isForm ">
          <div class="modal-header">
            <h1 class="modal-title fs-5" :id="id+`-customModalLabel`">{{ title }}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <slot  />
          </div>
          <div class="modal-footer">
            <button type="button" @click="$emit(`close`)" :id="id+`-closeModal`" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
        <div class="modal-content" v-else>
          <div class="modal-header">
            <h1 class="modal-title fs-5" :id="id+`-customFormModalLabel`">{{ title }}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form @submit.prevent="submitForm">
            <div class="modal-body">
              <slot  />
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">Submit</button>
              <button type="button" @click="$emit(`close`)" :id="id+`-closeFormModal`" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomModal",
  created() {
    this.id = `modal-`+generateUUID()
  },
  data(){
    return {
      id : null,
    }
  },
  props: {
    isForm : {
      type : Boolean,
      required: false,
      default : false,
    },
    title: {
      type: String,
      required: true,
    },
    size : {
      type: String,
      required: false,
      default : "xl"
    }
  },
  methods : {
    openModal(){
      $utils.hideLoading();
      document.getElementById(this.id+`-modalButton`).click();
    },
    closeModal(){
      if (this.isForm){
        return document.getElementById(this.id+`-closeFormModal`).click();
      }
      return document.getElementById(this.id+`-closeModal`).click();
    },
    submitForm(){
      this.closeModal();
      this.$emit(`submit`);
    }
  }
}
</script>

<style scoped>

</style>