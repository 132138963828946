import {Storage} from "@/services/Storage";
import axios from "axios";
import router from "@/router";

export default {
    name: 'user',
    state: {
        current_user: undefined,
        current_session: undefined,
        allUsers: [],
        userTypes: ['Super_User', 'Developer', 'Technician', 'Sales'],
        attemptedLoginRoute : { name : `user-dashboard` }
    },
    getters: {
        getCurrentUser: (state) => state.current_user,
        getAllUsers: (state) => state.allUsers,
        getUserTypes: (state) => state.userTypes,
        getAttemptedRoute: (state) => state.attemptedLoginRoute,
        getUserByID: (state) => (id)=> state.allUsers.find(user => user.id === id),
    },
    mutations: {
        setCurrentUser: async (state, data) => {
            if (data) {
                await Storage.setCurrentUser(data);
                state.current_user = data
            } else {
                data = await Storage.getCurrentUser();
                state.current_user = data
            }
        },

        setUsers: async (state, data) => {
            if (data) await Storage.setUsers(data)
            else data = await Storage.getAllUsers();
            state.allUsers = data;
        },
        setUserTypes: (state, data) => state.userTypes = data,
        setAttemptedRoute: (state, data) => state.attemptedLoginRoute = data,
        setSessionID:async (state , data) => {
            if (data) await Storage.setSessionID(data)
            else data = await Storage.getSessionID();
            state.current_session = data;
        }
    },
    actions: {
        getAllUsers: async (context) => {
            let user = await Storage.getCurrentUser();
            return axios.get(ticketApi + `user/getAll`)
                .then(async ({data}) => {
                    context.commit('setUsers', data)
                    if (user) {
                        let currentUserID = user.id;
                        let updatedData = data.find(userData => userData.id === currentUserID);
                        if (updatedData) {
                            return context.commit('setCurrentUser', updatedData);
                        }
                        responses.showInfo(`Invalid User ID : ${currentUserID} , Please Login`)
                        return router.push({name: 'login'})
                    }
                });
        },
        userLogin: (context, payload) => {
            return axios.post(ticketApi + 'user/Login', payload)
                .then(({data}) => {
                    context.commit('setCurrentUser', data)
                    return router.push({name: 'OTP'});
                }).catch(err => errorHandler.tomcatError(err))
        },

        verifyOtp: (context, payload) => {
            console.log(payload)
            return axios.post(ticketApi + 'user/verifyOtp', payload)
                .then(({data}) => {
                    context.commit(`setSessionID`,data)
                    let route = context.getters.getAttemptedRoute;
                    let routeName = route.name;
                    const resolvedRoute = router.resolve({ name: routeName });
                    let exists = resolvedRoute.route.name === routeName;
                    if(!exists) route = { name : `user-dashboard` };
                    return router.push(route);
                }).catch(err => errorHandler.tomcatError(err));
        },

        createUser: (context, payload) => {
            let phoneNumber = payload.phone_number;
            payload.phone_number = `260`+phoneNumber;
            return axios.post(ticketApi + `user`, payload)
                .then(() => context.dispatch('getAllUsers'))
        },

        updateUser: (context, payload) => {
            let ID = payload.id;
            let phoneNumber = payload.phone_number;
            payload.phone_number = `260`+phoneNumber
            return axios.put(ticketApi + `user/${ID}`, payload)
                .then(() => context.dispatch('getAllUsers'))
        },

        getUserByID: (context, id) => {
            return axios.get(ticketApi + `user/${id}`)
                .then(({data}) => data)
        },
    }
}
