import Vue from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faExternalLink,
    faAddressCard,
    faArrowLeft,
    faArrowRightFromBracket,
    faArrowUpRightFromSquare,
    faBars,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircleCheck as faCircleCheckSolid,
    faCircleExclamation,
    faCommentDots,
    faComments,
    faCopy,
    faEllipsis,
    faEllipsisVertical,
    faFileCircleCheck,
    faFileInvoiceDollar,
    faHome,
    faListCheck,
    faLocationDot,
    faLocationPin,
    faPencil,
    faPenToSquare,
    faPhone,
    faPlus,
    faRightToBracket,
    faSearch,
    faTrash,
    faPrint,
    faTrashCan,
    faUsers,
    faCalendar,
    faEnvelope,
    faBoxesStacked,
    faRobot,
    faUser,
    faLock,
    faTable,
    faFileDownload,
    faBell,
    faCircleXmark,
    faChartSimple,
    faArrowTrendUp,
    faClock,
    faPhoneVolume,
    faClipboardCheck,
    faHandHoldingDollar,
    faArrowsRotate,
    faArrowCircleRight,
    faEye,faBoxOpen,faTaxi,faBus,faBuilding,faHelmetSafety,faHourglass,faHeadset,faMoneyBill1Wave,faMoneyBill,faReceipt,faMoneyBills


} from '@fortawesome/free-solid-svg-icons'
import {faCircleCheck, faCommentDots as faCommentDotsR} from '@fortawesome/free-regular-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(faEllipsisVertical, faEllipsis, faPlus, faArrowLeft, faHome, faBars, faChevronLeft, faCopy,faEnvelope,faRobot,faUser,faTable,faChartSimple,
    faChevronRight, faCircleExclamation, faPencil, faTrash, faUsers, faLocationPin, faAddressCard, faLocationDot, faPenToSquare,faLock,faMoneyBills,faExternalLink,
    faTrashCan, faRightToBracket, faCommentDots, faArrowRightFromBracket, faCircleCheckSolid, faCircleCheck, faChevronUp,faBoxesStacked,faFileDownload,faArrowsRotate,
    faChevronDown, faListCheck, faComments, faPrint,faCommentDotsR, faFileInvoiceDollar, faFileCircleCheck, faArrowUpRightFromSquare, faSearch,faCircleXmark,faHandHoldingDollar,faArrowCircleRight,
    faPhone, faCalendar, faBell, faArrowTrendUp, faClock,  faPhoneVolume, faClipboardCheck,faEye,faBoxOpen,faTaxi,faBus,faBuilding,faHelmetSafety,faHourglass,faHeadset,faMoneyBill1Wave,faMoneyBill,faReceipt
)

