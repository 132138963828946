import {DateTimeFormatter, Duration, LocalDateTime , LocalDate} from "@js-joda/core";

function formatDateNumber(number) {
    return number >= 10 ? number : `0` + number;
}
function addIntForDate(number){
    return formatDateNumber(number)
}
let tomorrow = LocalDate.now().plusDays(1);
tomorrow = tomorrow._year+'-'+addIntForDate(tomorrow._month)+'-'+addIntForDate(tomorrow._day);

function formatCustomDateTime(dateTime, pattern) {
    const day = addIntForDate(dateTime.dayOfMonth());
    const month = addIntForDate(dateTime.monthValue());
    const year = dateTime.year();
    const hour = addIntForDate(dateTime.hour());
    const minute = addIntForDate(dateTime.minute());
    const second = addIntForDate(dateTime.second());

    switch (pattern) {
        case "yyyy-MM-dd HH:mm:ss":
            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        case "E dd MMM, HH:mm":
            return `${dateTime.dayOfWeek().name().substring(0, 3)} ${day} ${dateTime.month().name().substring(0, 3)}, ${hour}:${minute}`;
        case "dd/MM/yyyy, HH:mm":
            return `${day}/${month}/${year}, ${hour}:${minute}`;
        case "HH:mm":
            return `${hour}:${minute}`;
        default:
            throw new Error("Unsupported pattern");
    }
}

const defaultFormat = "yyyy-MM-dd HH:mm:ss";
const thisYearFormat = "E dd MMM, HH:mm";
const otherYearFormat ="dd/MM/yyyy, HH:mm";
const todayFormat = "HH:mm";

function parseDate(dateObject) {
    let date = dateObject.date;
    let time = dateObject.time;
    return `${date.year}-${formatDateNumber(date.month)}-${formatDateNumber(date.day)} ${formatDateNumber(time.hour)}:${formatDateNumber(time.minute)}:${formatDateNumber(time.minute)}`;
}

function handleDateExpiry(dateString) {
    dateString = dateString + 'T00:00:00';
    let date = LocalDateTime.parse(dateString);
    switch (date.dayOfWeek()._name) {
        case 'SATURDAY':
            date = date.minusDays(1)
            break;
        case 'SUNDAY':
            date = date.minusDays(2)
            break;
    }
    return formatCustomDateTime(date,defaultFormat).slice(0, -9);
}

export const dateTimeManager = {
    formatJavaDate(dateObject) {
        let date = dateObject.date;
        return `${date.year}-${formatDateNumber(date.month)}-${formatDateNumber(date.day)}`;
    },
    formatJavaDateTime(dateObject) {
        if (!dateObject) {
            return `Invalid Date`;
        }
        let date = dateObject.date;
        let time = dateObject.time;
        return `${date.year}-${formatDateNumber(date.month)}-${formatDateNumber(date.day)}T${formatDateNumber(time.hour)}:${formatDateNumber(time.minute)}`;
    },

    addHoursToDate(dateObject,duration) {
        if (!dateObject) {
            return `Invalid Date`;
        }

        if (!duration) {
            duration = 2;
        }
        let parsedDate = parseDate(dateObject);
        let date = LocalDateTime.parse(parsedDate, DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss"))
            .plusHours(Number(duration));
        return formatCustomDateTime(date,defaultFormat);
    },
    addHoursToParsedDate(parsedDate,duration) {
        let date = LocalDateTime.parse(parsedDate, DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss"))
            .plusHours(Number(duration));
        return formatCustomDateTime(date,defaultFormat);
    },



    dateForDisplay(dateObject) {
        let formattedDate = parseDate(dateObject)
        return new Date(formattedDate).toString().slice(3, 21);
    },
    shortenDisplay(dateObject) {
        let parsedDate = parseDate(dateObject);
        let formattedDate = LocalDateTime.parse(parsedDate, DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss"))
        let now = LocalDateTime.now()
        if (formattedDate.toLocalDate().equals(now.toLocalDate())) {
            return `Today at `+formatCustomDateTime(formattedDate,todayFormat)
        } else if (formattedDate.toLocalDate().year() === now.toLocalDate().year()) {
            return formatCustomDateTime(formattedDate,thisYearFormat)
        } else {
            return formatCustomDateTime(formattedDate,otherYearFormat)
        }
    },
    shortenStandardDisplay(parsedDate) {
        if (!parsedDate) return null;
        parsedDate = parsedDate.replaceAll("T"," ");
        let formattedDate = LocalDateTime.parse(parsedDate, DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss"))
        let now = LocalDateTime.now()
        if (formattedDate.toLocalDate().equals(now.toLocalDate())) {
            return `Today at `+formatCustomDateTime(formattedDate,todayFormat)
        } else if (formattedDate.toLocalDate().year() === now.toLocalDate().year()) {
            return formatCustomDateTime(formattedDate,thisYearFormat)
        } else {
            return formatCustomDateTime(formattedDate,otherYearFormat)
        }
    },

    getStartOfThisMonth(){
        return formatCustomDateTime(LocalDateTime.now().withDayOfMonth(1),defaultFormat)
    },

    getNow() {
        return formatCustomDateTime(LocalDateTime.now(),defaultFormat);
    },

    getTomorrow() {
        return formatCustomDateTime(LocalDateTime.now().plusDays(1),defaultFormat);
    },

    getNext7Days() {
        return formatCustomDateTime(LocalDateTime.now().plusDays(7),defaultFormat);
    },

    getYesterday() {
        return formatCustomDateTime(LocalDateTime.now().minusDays(1),defaultFormat);
    },

    getNextMonthlyExpiryDate() {
        let dateString = formatCustomDateTime(LocalDateTime.now().plusMonths(1),defaultFormat).slice(0, -11) + '07';
        return handleDateExpiry(dateString);
    },

    getNextYearlyExpiryDate() {
        let dateString = formatCustomDateTime(LocalDateTime.now().plusYears(1),defaultFormat).slice(0, -11) + '07';
        return handleDateExpiry(dateString);
    },

    getTodayRange(){
        let today = LocalDate.now();
        today = today._year+'-'+addIntForDate(today._month)+'-'+addIntForDate(today._day);
        return { from : today,to : tomorrow}
    },

    getYesterdayRange(){
        let today = LocalDate.now();
        today = today._year+'-'+addIntForDate(today._month)+'-'+addIntForDate(today._day);

        let yesterday = LocalDate.now().minusDays(1);
        yesterday = yesterday._year+'-'+addIntForDate(yesterday._month)+'-'+addIntForDate(yesterday._day);
        return { from : yesterday,to : today}
    },

    getLastWeekRange(){
        let startOfWeek = LocalDate.now().minusDays(7);
        startOfWeek = startOfWeek._year+'-'+addIntForDate(startOfWeek._month)+'-'+addIntForDate(startOfWeek._day)
        return { from : startOfWeek,to : tomorrow}
    },


    getLastMonthRange(){
        let firstDayOfLastMonth = LocalDate.now().minusMonths(1);
        firstDayOfLastMonth = firstDayOfLastMonth._year+'-'+addIntForDate(firstDayOfLastMonth._month)+'-01'

        let now = LocalDate.now();
        let firstDayOfThisMonth = now._year + '-' + addIntForDate(now._month) + '-01';

        return { from : firstDayOfLastMonth,to : firstDayOfThisMonth}
    },

    getThisMonthRange(){
        let now = LocalDate.now();
        let firstDayOfMonth = now._year + '-' + addIntForDate(now._month) + '-01';
        return { from : firstDayOfMonth,to : tomorrow}
    },

    getThisYearRange(){
        let startOfYear = LocalDate.now()._year+'-01-01'
        return { from : startOfYear,to : tomorrow}
    },

    getNextTwoDaysForForm() {
        return formatCustomDateTime(LocalDateTime.now().plusDays(2),defaultFormat)
            .replace(' ', 'T')
            .slice(0, -3)
    },

    getNextWeekForForm() {
        return formatCustomDateTime(LocalDateTime.now().plusWeeks(1),defaultFormat)
            .replace(' ', 'T')
            .slice(0, -3)
    },


    timeAgo(dateObject, showAgoIfLonger) {
        let parsedDate = parseDate(dateObject);
        let date = LocalDateTime.parse(parsedDate, DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss"))
        let now = LocalDateTime.now();
        let duration = Duration.between(date, now);
        console.log(duration)
        console.log(duration.toJSON())
        console.log(duration.toString())
        if (duration.toDays() > 1) {
            console.log("Duration greater than 1: " + duration.toDays())
        } else if (duration.toDays() === 1) {
            console.log("Duration greater equal to 1: " + duration.toDays())
        } else {
            console.log("Duration greater equal to 1: " + duration.toDays())
        }
    },

    hasDatePassed(date){
      let now = new Date();
      let other = new Date(date);
      return now > other;
    },


}