import Vue from 'vue'
import Vuex from 'vuex'
import user_module from "@/store/modules/user_module";
import smart_invoice_module from "@/store/modules/smart-invoice";
import ticket_module from "@/store/modules/ticket_module";
import customer_module from "@/store/modules/customer_module";
import ticket_notes_module from "@/store/modules/ticket_notes_module";
import ticket_tasks_module from "@/store/modules/ticket_tasks_module";
import ticket_document_module from "@/store/modules/ticket_document_module";
import print_module from "@/store/modules/print_module";
import emailModule from "@/store/modules/email-module";
import chatModule from "@/store/modules/chat-module";
import expenseModule from "@/store/modules/expense-module";
import timeModule from "@/store/modules/time-module";
import axios from "axios";
import home_screen_module from "@/store/modules/home_screen_module";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading: false,
        isUpdateAvailable: false,
        isSynergyLive : true,
    },
    getters: {
        getLoadingState: (state) => state.loading,
        getSynergyAvailability: (state) => state.isSynergyLive,
        getUpdateAvailability: (state) => state.isUpdateAvailable,
},
    mutations: {
        setLoadingState: (state, payload) => state.loading = payload,
        setUpdateAvailability: (state, payload) => state.isUpdateAvailable = payload,
    },
    actions: {
        getDashboardData: (context) => {
            return axios.all([
                context.dispatch('getCurrentUserTicketCount'),
                context.dispatch('getOpenTicketCount'),
                context.dispatch('getTicketsByTab', 1),
                context.dispatch('getAllTicketsGroupedByCustomer'),
            ]);
        },
        getPreLoginData: (context, isRefresh = false) => {
            return axios.all([
                context.dispatch('getAllUsers'),
                context.dispatch('getAllDebtors'),
                context.dispatch('getAllTicketStatuses'),
                context.dispatch('getAllTicketTypes'),
                context.dispatch('getPaymentOptions'),
            ]);
        },

        sendRequest(context, { url, payload }) {
            const maxRetries = 3;
            let currentRetry = 0;
            if (!payload.localid){
                payload.localid = generateUUID();
            }

            const attemptRequest = () => {
                return axios.post(url, payload)
                    .then(({ data }) => {
                        if (!data.processed) {
                            // Check if the response indicates unprocessed data and if retries are available
                            if (currentRetry < maxRetries) {
                                currentRetry++;
                                console.log(`Attempt ${currentRetry}: Data unprocessed, retrying...`);
                                return attemptRequest(); // Retry the request
                            }
                            // Throw an error if max retries are reached with unprocessed data
                            throw new Error('Data still unprocessed after maximum retries : '+maxRetries);
                        }
                        return data.response; // Return the response if data is processed
                    }).catch(err => {
                        if (err.code === 'ECONNABORTED' || err.message.includes('timeout')) {
                            if (currentRetry < maxRetries) {
                                currentRetry++;
                                console.log(`Attempt ${currentRetry}: Retrying...`);
                                return attemptRequest(); // Retry the request
                            }
                        }
                        throw err; // Re-throw the error if it's not a timeout or retries are exhausted
                    });
            };
            return attemptRequest()
                .then(response => response)
                .catch(err => errorHandler.tomcatError(err));
        }
    },
    modules: {
        user_module,
        ticket_module,
        customer_module,
        ticket_notes_module,
        ticket_tasks_module,
        ticket_document_module,
        home_screen_module,
        print_module,
        emailModule,smart_invoice_module,
        chatModule,expenseModule,timeModule
    }
})
