import axios from "axios";

export default {
    name: 'ticket-notes',
    state: {
        editNote: {
            Note: ``,
            internal: true
        }
    },
    getters: {
        getEditNote: (state) => state.editNote
    },
    mutations: {
        enableEditMode: (state) => state.editNote.edit = true,
        disableEditMode: (state) => state.editNote.edit = false,
        editTicketNote: (state, data) => {
            state.editNote = {
                id: data.id,
                Note: data.Note,
                internal: data.internal,
                edit: true,
                readOnly: false
            }
        },
        setInternalStatus: (state, internal) => state.editNote.internal = internal,
        replyToNote: (state, data) => {
            state.editNote = {
                Note: ``,
                internal: data.internal,
                replyTo: data.id,
                employee: data.createduser,
                edit: true,
                readOnly: false
            }
        },
        clearEditNote: (state) => {
            state.editNote = {
                Note: ``,
                internal: true,
                edit: false,
                readOnly: false
            }
        }
    },
    actions: {
        enableEditMode: (context) => context.commit('enableEditMode'),
        disableEditMode: (context) => context.commit('disableEditMode'),
        editTicketNote: (context, data) => {
            context.commit('editTicketNote', data)
            context.commit('enableEditMode')
        },
        replyToNote: (context, data) => {
            context.commit('replyToNote', data)
            context.commit('disableEditMode')
        },
        clearEditNote: (context) => {
            context.commit('clearEditNote')
            context.commit('disableEditMode')
        },

        getNotesByTicket:async (context ,id) => {
            return axios.get(ticketApi+`ticket/notes/getByTicket/${id}`)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        }
    }
}
